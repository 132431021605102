@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'LUFGA';
    src: url('../public/static/fonts/LufgaLight.ttf');
}

@font-face {
    font-family: 'LUFGA BOLD';
    src: url('../public/static/fonts/LufgaSemiBold.ttf');
}
